import configData from '../config/config.json';
import authHeader from "./auth-header";
const qs = require('qs')
const axios = require('axios');

const API_URL = configData.ACCOUNTS_URL;
const Login_URL = configData.AUTH_URL

const register = (user) => {
  const headers = authHeader()
  return axios.post(API_URL+"create", user, {
    headers: headers
  });
};

const deleteUser = (userID) => {
  return axios.delete(API_URL+'delete', {
    headers: authHeader(),
    params: {userId: userID}
  });
};

const updateUser = (user) => {
  return axios.put(`${API_URL}${user.id}`, user, {
    headers: authHeader()
  });
}

const sendResetPasswordLink = (data) => {
  return axios.post(API_URL+'sendResetPasswordLink', data);
};

const resetPassword = (data) => {
  return axios.post(API_URL+'resetPasswordFromLink', data);
};

const login = async (user) => {
  var data = qs.stringify({
    username: user.username, 
    password: user.password,
    client_id:"NativeAppClient",
    client_secret:"2bbc6933-8ed6-4e15-b06d-5c679ad07bd8",
    scope:"AccountsApi RegistrationApi ChallengesApi PaymentsApi offline_access VideoApi",
    grant_type:"password"
});
   var config = {
     method: 'post',
     url: Login_URL,
     headers: { 
       'Content-Type': 'application/x-www-form-urlencoded'
     },
     data : data
   };

   return axios(config);
};

const refreshToken = (token) => {
  var data = qs.stringify({
    client_id:"NativeAppClient",
    client_secret:"2bbc6933-8ed6-4e15-b06d-5c679ad07bd8",
    scope:"AccountsApi RegistrationApi ChallengesApi PaymentsApi offline_access VideoApi",
    grant_type:"refresh_token",
    refresh_token: token
});
   var config = {
     method: 'post',
     url: Login_URL,
     headers: { 
       'Content-Type': 'application/x-www-form-urlencoded'
     },
     data : data
   };
   return axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     console.log(error);
     return error;
   });
}

const logout = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("user");
};

const authService = {
  register,
  deleteUser,
  login,
  logout,
  refreshToken,
  sendResetPasswordLink,
  resetPassword,
  updateUser
};

export default authService;
